import * as React from "react"
import Layout from "../components/layout"
import { graphql } from 'gatsby'
import Seo from "../components/seo"
import Form from "../components/form" 

class ContactPage extends React.Component {
  render() {
    const {data} = this.props
    return (
      <Layout classes="contact-page">
        <Seo title="Contact Us" />
        <div className="spacer half"></div>
        <div className="p20 mh-40 flex m-wrap">
          <div className="w-50 m-100 m-mb40">
            <h1 className="m0 fade-right" data-sal data-sal-delay="300">{data.allDatoCmsContact.edges[0].node.contactTitle}</h1>
            <div className="mt40 op-50 max-475 text-large fade-in" data-sal data-sal-delay="500">
              {data.allDatoCmsContact.edges[0].node.contactContent} 
            </div>
          </div>
          <div className="w-50 m-100">
            <div className="form fade-in" data-sal data-sal-delay="500">
              <p className="text-yellow h2 m0 mb20">Contact Form</p>
              <Form />
            </div>
          </div>
        </div> 
        <div className="spacer"></div>
      </Layout>
    )
  }
}

export const contactQuery = graphql`
  query contactQuery {
    allDatoCmsContact {
      edges {
        node {
          contactContent
          contactTitle
        }
      }
    }
  }
`

export default ContactPage
