import * as React from "react"

class EnquiryForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      formSubmitted: false,
      message: "",
      dropdown:""
    }
  }
  componentDidMount() {
    document.querySelector("form").addEventListener("submit", this.handleSubmit)
    if(this?.props?.linkState?.productName){
      this.setState({message:`I'd like to enquire about `+this.props.linkState.productName})
      this.setState({dropdown:true}) 
    } 
  }
  handleSubmit = e => {
    e.preventDefault()
    let myForm = document.getElementById("contact")
    let formData = new FormData(myForm)
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => {
        const form = document.querySelector("form")
        form.classList.add("fade-out")
        setTimeout(() => {
          this.setState({ formSubmitted: true },()=>{
            const thankYou = document.querySelector("#thank-you")
            setTimeout(()=>{
              thankYou.classList.add("fade-in-thank-you")
            },100)
           
          })
        }, 1250)
      })
    .catch(error => console.error(error))
    document.querySelector("form").addEventListener("submit", this.handleSubmit);
  }

  render() {
    const formSubmitted = this.state.formSubmitted
    if(!formSubmitted){
    return (
      <div className="form-wrapper max-475">
        <form method="POST" data-netlify="true" id="contact" className="flex flex-wrap">
          <input type="hidden" name="form-name" value="contact" />
          <div className="w-50 m-100">
            <input name="First Name" required type="text" className="input" placeholder="First Name *"/>
          </div>
          <div className="w-50 m-100">
            <input name="Last Name" required type="text" className="input" placeholder="Last Name *"/>
          </div>
          <div className="w-50 m-100">
            <input name="Email" required type="text" className="input" placeholder="Email *" />
          </div>
          <div className="w-50 m-100">
            <input name="Number" type="text" className="input" placeholder="Mobile" />
          </div>
          <div className="w-100">
            <input name="Business" type="text" className="input" placeholder="Details" />
          </div>
          <button type="submit" className="submit-button"><span className="link with-arrow">Submit</span></button>
        </form>
      </div>
    )}else{
      return <div className="form-section  thank-you" id="thank-you"> Thank You for submitting. We'll be in touch soon.</div>
    }
  }
}

export default EnquiryForm
